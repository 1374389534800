import { Component } from "vue";

export const useBlockContainer = () => {
  const BlocksBlockProductList = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProductList.vue"),
    ),
  );

  const BlocksBlockPlpTopBanner = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockPlpTopBanner.vue"),
    ),
  );

  const Block2ColSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/Block2ColSlider.vue"),
    ),
  );

  const BlocksBlockProperties = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProperties.vue"),
    ),
  );

  const BlockRecentlyView = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/BlockRecentlyView.vue"),
    ),
  );

  const BlockAccordion = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockAccordion.vue"),
    ),
  );

  const BlockArticles = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockArticles.vue")),
  );

  const BlockBestseller = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockBestseller.vue"),
    ),
  );

  const BlockComparison = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockComparison.vue"),
    ),
  );

  const BlockInstagram = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockInstagram.vue"),
    ),
  );

  const MarketingCollections = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/MarketingCollections.vue"),
    ),
  );

  const BlockMedia = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockMedia.vue")),
  );

  const BlockPromoted = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockPromoted.vue")),
  );

  const BlockRichText = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockRichText.vue")),
  );

  const BlockSale = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockSale.vue")),
  );

  const BlockWishlist = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockWishlist.vue")),
  );

  const BlockCategories = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/BlockCategories.vue"),
    ),
  );

  const BlockDefaultSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockDefaultSlider.vue"),
    ),
  );

  const BlockTopSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockTopSlider.vue"),
    ),
  );

  const CustomBlocksHomeSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/Home/HomeSlider.vue"),
    ),
  );

  const CustomBlocksFormContactUs = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/FormContactUs.vue"),
    ),
  );

  interface ComponentsInterface {
    [key: string]: Component | string;
  }

  const defaultComponentOptions: ComponentsInterface = {
    catalog: markRaw(BlocksBlockProductList.value),
    accordion: markRaw(BlockAccordion.value),
    articles: markRaw(BlockArticles.value),
    bestseller: markRaw(BlockBestseller.value),
    categories: markRaw(BlockCategories.value),
    comparison: markRaw(BlockComparison.value),
    entity: markRaw(BlocksBlockProperties.value),
    instagram: markRaw(BlockInstagram.value),
    "marketing-collections": markRaw(MarketingCollections.value),
    media: markRaw(BlockMedia.value),
    promoted: markRaw(BlockPromoted.value),
    "recently-viewed": markRaw(BlockRecentlyView.value),
    "rich-text-module": markRaw(BlockRichText.value),
    sale: markRaw(BlockSale.value),
    slider: markRaw(BlockDefaultSlider.value),
    "slider-2-columns": markRaw(Block2ColSlider.value),
    wishlist: markRaw(BlockWishlist.value),
    "top-slider": markRaw(BlockTopSlider.value),
    "plp-top-banner": markRaw(BlocksBlockPlpTopBanner.value),
  };

  const customComponentsOptions: ComponentsInterface = {
    "home-slider": markRaw(CustomBlocksHomeSlider.value),
    "form-contact-us": markRaw(CustomBlocksFormContactUs.value),
  };

  function getComponent(name: string, identifier: string): Component | string {
    const componentMap = {
      "plp-shop-by-": "plp-shop-by",
    };

    for (const key in componentMap) {
      if (name.includes(key)) {
        name = componentMap[key];
        break;
      }
    }
    return (
      customComponentsOptions[name] || defaultComponentOptions[identifier] || ""
    );
  }

  return { defaultComponentOptions, customComponentsOptions, getComponent };
};
